<template>
  <validation-provider
    #default="validationContext"
    name="languages"
    rules="required"
  >
    <b-form-group
      label-for="languages"
      label="Select Language"
      :state="getValidationState(validationContext)"
    >
      <v-select
        id="languages"
        v-model="selectedLanguage.language_id"
        label="name"
        required
        :reduce="lang => lang.id"
        :options="languagesList"
        class="select-size-lg"
      />
      <b-form-invalid-feedback>
        {{ validationContext.errors[0] }}
      </b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>

<script>
import {
  BCol, BFormGroup, BFormInvalidFeedback,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import axios from 'axios'
import { required } from '@validations'
import { ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    BCol,
    BFormGroup,
    BFormInvalidFeedback,
    ValidationProvider,
    vSelect,
  },
  props: {
    selectedLanguage: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      languagesList: [],
    }
  },
  setup() {
    const {
      getValidationState,
    } = formValidation()

    return {
      getValidationState,
    }
  },
  created() {
    this.getLanguages()
  },
  methods: {
    async getLanguages() {
      const response = await axios.get('get/language')
      this.languagesList = response.data.success
    },
  },

}
</script>

<style>
.selectLanguage {
  color: red;
}
</style>
